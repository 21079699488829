.contact {
  &__input {
    @apply w-full;
    @apply rounded-md border-2 placeholder-gray-400 outline-none;
    @apply border-secondary-200 focus:border-secondary-400;
    @apply dark:border-secondary-800 dark:focus:border-secondary-600;
    @apply bg-white dark:bg-white dark:bg-opacity-90;
    @apply text-gray-600 font-semibold;
    @apply px-3;
    @apply py-1;

    &__error {
      @apply border-red-700 focus:border-red-700;
      @apply dark:border-red-800 dark:focus:border-red-800;
    }
  }
  &__label__error {
    @apply block px-3 text-right text-xs font-semibold;
    @apply text-red-700 dark:text-red-600;
  }

  &__button {
    @apply bg-secondary-600 outline-none hover:bg-secondary-700;
    @apply dark:bg-blueGray-600 outline-none dark:hover:bg-blueGray-700;
    @apply border-2 border-secondary-600;
    @apply dark:border-blueGray-600;
    @apply text-white uppercase font-semibold;
    @apply px-5 py-2 rounded-lg;
    @apply hover:shadow-lg transition-all duration-150;

    &__success {
      background-color: rgba(22, 163, 74, 0.8) !important;
    }
    &__error {
      background-color: rgba(239, 68, 68, 0.8) !important;
      @apply bg-red-500;
    }
  }
}
