.header {
  @apply fixed z-20 w-full px-5 top-0;
  @apply grid grid-cols-6 items-center;
  @apply text-white dark:text-gray-300;
  @apply transition-all duration-100;

  &__bg__color {
    @apply bg-gradient-to-l from-secondary-500   to-primary-500;
    @apply dark:from-blueGray-700 dark:to-blueGray-900;
    @apply transition-all duration-500;
    @apply h-14 sm:h-12 2xl:h-24;
    @apply border-b sm:border-none border-primary-600 dark:border-gray-600;

    &__top {
      @apply bg-black bg-opacity-30;
      @apply transition-all duration-500;
      @apply h-20 sm:h-24 lg:h-28 2xl:h-36;
    }
  }
  &__bg__responsive__color {
    @apply bg-gradient-to-t from-secondary-500   to-primary-500;
    @apply dark:from-blueGray-700 dark:to-blueGray-900;
  }

  &__logo {
    @apply col-span-4 sm:col-span-3 md:col-span-2;

    &__label {
      @apply text-center font-semibold  text-xl xl:text-2xl 2xl:text-3xl;
    }

    &__image {
      @apply flex justify-start sm:justify-center;
      img {
        @apply w-14 sm:w-16 md:w-20 lg:w-24 2xl:w-32;
      }
    }
  }

  &__nav {
    @apply flex justify-end col-span-2 sm:col-span-3 md:col-span-4;
  }

  &__ul {
    @apply hidden md:flex;
  }

  &__li {
    @apply mx-3 lg:mx-2 xl:mx-4;
    @apply font-semibold text-xl xl:text-2xl 2xl:text-3xl;
  }

  &__toggle__theme {
    @apply cursor-pointer mx-4 self-center;
    @apply text-2xl md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl;
  }

  &__responsive__menu {
    @apply md:hidden cursor-pointer transition-all duration-300;
  }

  &__responsive__menu.active {
    @apply transform rotate-90;
  }
}

/* MENU RESPONSIVO */
.responsive {
  &__menu {
    @apply w-60 sm:w-96 min-h-full flex flex-col justify-around items-center;
    @apply transition-all duration-500;
    @apply text-white dark:text-gray-300;
  }
  &__menu__backdrop {
    @apply transition-all duration-300;
    @apply md:hidden;
    @apply fixed top-0 left-0 h-full w-full z-20;
    @apply bg-white bg-opacity-60 dark:bg-black dark:bg-opacity-60;
    @apply transform;
  }
}

.active__link {
  color: yellow;
  @apply dark:text-white;
}
.link:hover {
  color: yellow;
}
.link {
  @apply transition-all duration-300 dark:hover:text-white;
}
