.values {
  @apply relative;
  @apply pb-10 lg:pb-12 xl:pb-16 2xl:pb-20;

  &__title {
    @apply py-3 text-center;
  }

  &__card {
    @apply col-span-6 sm:col-span-3 xl:col-span-2;
    @apply flex flex-col;
    @apply bg-white dark:bg-blueGray-600;
    @apply border-2 border-gray-100 dark:border-blueGray-500;
    @apply transition-all duration-500;
    @apply shadow-xl lg:hover:shadow;
    @apply transform scale-100 lg:hover:scale-95;

    &.vision {
      @apply sm:col-span-6 xl:col-span-2;
    }

    &__container {
      @apply grid grid-cols-6 gap-5;
    }

    &__image {
      @apply flex justify-center items-center py-5;

      img {
        @apply h-40;
      }
    }

    &__content {
      @apply p-4 pt-0 cursor-default;
      @apply text-base md:text-lg lg:text-xl 2xl:text-2xl;
      @apply dark:text-gray-200;

      h2 {
        @apply text-center font-bold py-3;
      }

      p {
        @apply text-justify;
        @apply leading-5 md:leading-5 sm:leading-4 xl:leading-6 2xl:leading-7;
      }

      li img {
        @apply w-4 inline-block;
      }
    }
  }
}
