.services {
  // background-image: url(https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80);
  background-image: url(https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;

  &__container {
    @apply grid grid-cols-2 mb-8 sm:mb-0;
  }

  &__backdrop {
    @apply bg-white bg-opacity-10;
    @apply dark:bg-black dark:bg-opacity-30;
    @apply py-7 sm:py-10 md:py-20 px-3;
  }

  &__def {
    @apply bg-white bg-opacity-90 dark:bg-blueGray-900 dark:bg-opacity-60;
    @apply sm:rounded-3xl p-5 mb-10;
    @apply text-gray-600 dark:text-gray-100 text-justify;

    h2 {
      @apply mb-3;
    }

    p {
      @apply text-base md:text-lg xl:text-xl 2xl:text-2xl;
      @apply leading-5 md:leading-5 sm:leading-4 xl:leading-6 2xl:leading-7;
    }
  }

  &__image {
    @apply flex flex-col justify-center items-center;
    @apply col-span-2 sm:col-span-1;
    @apply bg-blueGray-900 dark:bg-white;
    @apply bg-opacity-50 dark:bg-opacity-50;
    @apply py-5 sm:py-10 md:py-14;

    span {
      @apply uppercase font-semibold text-center block sm:hidden;
      @apply text-xl lg:text-3xl;
      @apply text-gray-100 dark:text-gray-800 pb-2;
    }

    img {
      filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
      @apply h-36 sm:max-h-32 lg:max-h-44 w-48;
    }
  }

  &__text {
    @apply flex justify-center items-center;
    @apply col-span-2 sm:col-span-1;
    @apply px-8 md:px-10 py-5;

    @apply bg-white dark:bg-blueGray-900;
    @apply bg-opacity-90 dark:bg-opacity-70;

    @apply text-base md:text-lg xl:text-xl 2xl:text-2xl;
    @apply leading-5 md:leading-5 sm:leading-4 xl:leading-6 2xl:leading-7;

    span {
      @apply mt-5 uppercase font-semibold hidden sm:block text-center mb-2;
    }

    li img {
      @apply w-4 inline-block;
    }
  }
}
