@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Rubik';
  src: url(./assets/fonts/Rubik.ttf);
  font-display: fallback; /* Define how the browser behaves during download */
}

html,
body {
  font-family: 'Rubik';
}

#root {
  @apply min-h-screen relative flex flex-col;
  @apply bg-gray-50 dark:bg-blueGray-700;
  @apply text-gray-600 dark:text-gray-200;
}

.drop__shadow {
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3));
}
.drop__shadow__10 {
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3));
}
.drop__shadow__15 {
  filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.3));
}

.size__container {
  @apply container;
  @apply max-w-md sm:max-w-lg md:max-w-xl lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl;
}

.size__title {
  @apply font-semibold text-2xl sm:text-3xl lg:text-5xl 2xl:text-7xl relative;
}

@supports ((-webkit-hyphens: auto) or (hyphens: auto)) {
  .justify {
    text-align: justify;
    -webkit-hyphens: auto; /* A día de hoy aún es necesario el prefijo para soportar a Safari */
    hyphens: auto;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
  }
}

button {
  @apply focus:outline-none;
}

/* ELIMINAR botones de input type number */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
