.up__page__ {
  &container {
    @apply fixed flex bottom-6 right-10 z-10 w-12 h-12;
    @apply rounded-full border border-gray-100 cursor-pointer;
    @apply bg-secondary-500 dark:bg-blueGray-700;
  }
  &icon {
    @apply text-white m-auto text-2xl;
    @apply transition-all duration-300 transform scale-100 hover:scale-125;
  }
}
