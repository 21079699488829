.contact {
  @apply bg-secondary-100 dark:bg-opacity-50 text-white;
  @apply bg-gradient-to-r from-primary-500 to-secondary-500 px-3 pb-6;
  @apply dark:from-blueGray-700 dark:to-blueGray-900;

  &__container {
    @apply grid grid-cols-6;
  }

  &__title {
    @apply col-span-6 order-1;
    @apply text-center font-semibold py-5 md:py-8;
  }

  &__social__networks {
    @apply col-span-6 sm:col-span-3 order-3 sm:order-2;
    @apply flex flex-col justify-around items-center sm:items-start xl:items-center;
  }

  &__form__container {
    @apply col-span-6 sm:col-span-3 order-2 sm:order-3;
    @apply grid grid-cols-1 gap-4;
  }
}
