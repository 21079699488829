.somos__ {
  &container {
    @apply grid grid-cols-6 gap-4 justify-center;
    @apply pt-10 lg:pt-12 xl:pt-16 2xl:pt-20;
    @apply pb-5 md:pb-7  xl:pb-8 2xl:pb-10;
    @apply px-5 sm:px-0;
  }

  &logo {
    @apply order-1 md:order-3;
    @apply col-span-6 md:col-span-2 xl:col-span-3;
    @apply flex justify-center items-center;

    img {
      @apply w-40 sm:w-60 md:w-52 xl:w-60;
    }
  }

  &title {
    @apply order-2 md:order-1;
    @apply col-span-6  px-3;

    h2 {
      @apply text-center font-semibold text-primary-600 dark:text-gray-300;
    }
  }

  &description {
    @apply order-3 md:order-2;
    @apply col-span-6 md:col-span-4 xl:col-span-3;
    @apply px-3 mx-auto;
    @apply flex justify-center items-center;

    p {
      @apply text-base md:text-lg xl:text-xl 2xl:text-2xl;
      @apply leading-5 sm:leading-4 md:leading-5 xl:leading-6 2xl:leading-7;
    }
  }
}
