.slider__ {
  &content {
    @apply absolute top-0 bottom-0 left-0 right-0 h-full w-full z-10 px-3 pt-10;
    @apply flex flex-col justify-center items-center;
    @apply bg-black bg-opacity-30 dark:bg-opacity-60;
    @apply text-white dark:text-gray-200;

    &__title {
      @apply font-serif;
      @apply text-4xl sm:text-5xl xl:text-6xl 2xl:text-7xl;
    }
    &__subtitle {
      @apply my-4 xl:my-6 2xl:my-8;
      @apply max-w-md sm:max-w-lg md:max-w-xl xl:max-w-3xl 2xl:max-w-4xl;
      @apply text-xl  sm:text-2xl xl:text-3xl 2xl:text-5xl;
      @apply leading-5 sm:leading-6 xl:leading-7 2xl:leading-10;
    }
    &__button {
      @apply bg-gradient-to-t from-secondary-500 to-primary-500 hover:from-secondary-600 hover:to-primary-600;
      @apply dark:from-blueGray-700 dark:to-blueGray-800 dark:hover:from-blueGray-600 dark:hover:to-blueGray-700;
      @apply rounded-md xl:rounded-lg 2xl:rounded-xl;
      @apply py-3 px-6 xl:px-6 2xl:py-4 2xl:px-9;
      @apply text-lg xl:text-xl 2xl:text-3xl leading-3 uppercase font-semibold;
    }
  }
}

// configuración básica del carousel
.awssld {
  --slider-height-percentage: 100%;
  --slider-transition-duration: 700ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #24c55d;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #2d5182;
  --control-bullet-active-color: #26456f;
  --loader-bar-color: #24c55d;
  --loader-bar-height: 6px;
}
// dimensionamiento responsive
@media (min-width: 480px) {
  .awssld {
    --slider-height-percentage: 75%;
  }
}
@media (min-width: 640px) {
  .awssld {
    --slider-height-percentage: 80%;
  }
}
@media (min-width: 768px) {
  .awssld {
    --slider-height-percentage: 65%;
  }
}
@media (min-width: 1024px) {
  .awssld {
    --slider-height-percentage: 55%;
  }
}
@media (min-width: 1280px) {
  .awssld {
    --slider-height-percentage: 50%;
  }
}

// activación de animaciones al ser slide activo - animate-css
.awssld__box .awssld__content .animate__animated {
  display: none;
}
.awssld__box.awssld--active .awssld__content .animate__animated {
  display: block;
}

// animación de fondeo ZOOM al ser slide activo
.awssld__box .awssld__content > img {
  @apply transition-all transform scale-100;
}
.awssld__box.awssld--active .awssld__content > img {
  transition-duration: 10000ms;
  @apply transform scale-125 ease-linear;
}
