.footer {
  @apply relative  mt-auto;
  @apply text-center text-white dark:text-gray-300;
  @apply bg-gradient-to-r from-primary-500 to-secondary-500;
  @apply dark:from-blueGray-700 dark:to-blueGray-900;

  &__title {
    @apply relative mt-5  uppercase text-2xl px-5 py-1 font-serif;
  }

  &__social {
    @apply grid grid-cols-1 sm:grid-cols-3 gap-4 my-5;

    &__icon {
      @apply text-4xl mb-2;
    }

    &__network {
      @apply flex flex-col justify-center items-center;

      a.label {
        @apply block border-t text-base sm:text-sm md:text-base lg:text-lg 2xl:text-xl;
      }
      a.icon {
        @apply transform transition-all duration-300 hover:scale-110;
      }
    }
  }

  &__copyright {
    @apply border-t-2  py-3 text-sm  lg:text-base 2xl:text-lg;
  }
}
